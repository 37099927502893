body {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 100%;
  box-sizing: border-box;
  background: #fff;
  line-height: 1;
  font-size: 1rem;
  font-weight: 400;
  color: #333;
  //font-family: Body_Open, sans-serif;
  font-family: sans-serif;
}

div,
p,
span,
input,
button,
img,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box;
}

a {
  margin: 0;
  padding: 0;
  border: 0;
  color: #000;
  text-decoration: none;
}

ul,
ol,
dl,
li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
}

:root {
  --them-BGcolor: #f5f5f5;
}

.text-hidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  max-width: 1200px;
  margin: 0 auto 2rem;
  padding: 0 0.75rem;
}

.text-elli-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-elli-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
}

.text-elli-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}

@font-face {
  font-family: 'P_ONE'; /* 自定义字体名称 */
  //src: url('/fonts/Montserrat-Bold.ttf'); /* 字体文件路径 */
  src: url("https://www.timelycoupons.com/fonts/Montserrat-Bold.woff2") format('woff2'),
        url("https://www.timelycoupons.com/fonts/Montserrat-Bold.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Body_Open'; /* 自定义字体名称 */
  //src: url('/fonts/Prompt-Regular.ttf'); /* 字体文件路径 */
  src: url('https://www.timelycoupons.com/fonts/Prompt-Regular.woff2') format('woff2'),
        url("https://www.timelycoupons.com/fonts/Prompt-Regular.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
